import React, { useContext, useEffect, useRef, useState } from "react";

import { useGviconfigurator } from "../hooks/useSiteConfigurator";
import { useSiteMetadata } from "../hooks/useSiteMetadata";

export const ThemeContext = React.createContext({
  colorMode: process.env.GATSBY_DEFAULT_THEME,
  localeLanguage: "",
  currentDomain: "",
  currentBrand: "",
  planetDomain: "",
  peopleDomain: "",
  currentSiteMeta: {},
  isLive: false,
  site: {}
});

export const ThemeProvider = ({ children }) => {
  const colorMode = process.env.GATSBY_DEFAULT_THEME;
  const currentBrand = process.env.GATSBY_PROJECT_NAV_TITLE.toLowerCase();
  const localeLanguage =
    process.env.GATSBY_PROJECT_LOCALE_LANGUAGE?.toLowerCase() || "en";

  const site = useSiteMetadata();

  const [isLive, setIsLive] = useState(false); //we are using for now, to determine what pages use new template - its temporary (6 months temporary).

  const [localLang, setLocalLang] = useState(localeLanguage);

  const configurator = useGviconfigurator();
  // console.log("useGviconfigurator", { localLang, configurator });

  const siteMetadataRef = useRef();
  siteMetadataRef.current = configurator?.world_contact_details[localLang];

  const [currentSiteMeta, setSiteMeta] = useState(
    siteMetadataRef?.current || {}
  );
  const [currentDomain, setDomain] = useState(
    siteMetadataRef?.current?.domain || "gvi.co.uk"
  );
  const [planetDomain, setPlanetDomain] = useState(
    `https://www.${siteMetadataRef?.current?.domain || currentDomain}`
  );
  const [peopleDomain, setPeopleDomain] = useState(
    `https://people.${siteMetadataRef?.current?.domain || currentDomain}`
  );

  useEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    const url = new URL(window?.location ?? location);
    const currentHost = url.host ?? url.hostname;

    switch (true) {
      case currentHost.includes("gviafrica.co.za"):
      case currentHost.includes("people-zar"):
      case currentHost.includes("planet-zar"):
        siteMetadataRef.current = configurator?.world_contact_details["za"];
        // console.log("context ZAR useEffect :: ", localLang);

        setLocalLang("za");
        setDomain(siteMetadataRef?.current?.domain);
        setSiteMeta(siteMetadataRef?.current);
        setPeopleDomain(`https://people.${siteMetadataRef?.current?.domain}`);
        setPlanetDomain(`https://www.${siteMetadataRef?.current?.domain}`);
        break;

      case currentHost.includes("gvi.ie"):
      case currentHost.includes("people-ie"):
      case currentHost.includes("planet-ie"):
        siteMetadataRef.current = configurator?.world_contact_details["ie"];
        // console.log("context IE useEffect :: ", localLang);

        setLocalLang("ie");
        setDomain(siteMetadataRef?.current?.domain);
        setSiteMeta(siteMetadataRef?.current);
        setPeopleDomain(`https://people.${siteMetadataRef?.current?.domain}`);
        setPlanetDomain(`https://www.${siteMetadataRef?.current?.domain}`);
        break;

      default:
      // localeLang = localeLanguage;
    }

    if (
      !url?.host?.includes("netlify.app") &&
      !url?.host?.includes("gatsbyjs.io") &&
      !url?.host?.includes("gviworld.com") &&
      !url?.host?.includes("localhost")
    ) {
      setIsLive(true);
    }
  }, []);

  //process traffic source

  // console.debug("testing values", {
  //   colorMode,
  //   siteMetadata: siteMetadataRef.current,
  //   currentDomain,
  //   currentSiteMeta,
  //   currentBrand,
  //   planetDomain,
  //   peopleDomain,
  //   localeLanguage: localLang,
  //   isLive
  // });

  return (
    <ThemeContext.Provider
      value={{
        colorMode,
        currentDomain,
        currentSiteMeta,
        currentBrand,
        planetDomain,
        peopleDomain,
        site,
        localeLanguage: localLang,
        isLive
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

/**
 * Notes: must be run in a useEffect, otherwise it will not work
 * process traffic source
 *  **/

export const processTrafficSource = () => {};

export const useTheme = () => {
  const context = useContext(ThemeContext);

  if (!context) {
    throw new Error("useTheme should be used in ThemeContext provider");
  }

  return context;
};
